var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.translates && _vm.translates[_vm.langUrl])?_c('div',{staticClass:"popup-request-confirmation"},[_c('div',{staticClass:"popup-request-confirmation__title"},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].popupTitle[_vm.lang]))]),_c('div',{staticClass:"popup-request-confirmation__close",on:{"click":_vm.close}}),(_vm.tripRequestActive && _vm.tripRequestActive.trip)?_c('div',{staticClass:"popup-request-confirmation__links"}):_vm._e(),_c('div',{staticClass:"popup-request-confirmation__info"},[_c('ValidationObserver',{ref:"validator",attrs:{"slim":""}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseSelect',{attrs:{"label":_vm.translates[_vm.langUrl].trip[_vm.lang],"value":_vm.bloggerTrip.trip,"items":_vm.filteredTrips,"item-text":"name","item-value":"_id","error-messages":errors[0],"readonly":!_vm.tripRequestActive.common},on:{"input":_vm.setTrip}})]}}],null,false,3506851287)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseDatePicker',{attrs:{"label":_vm.translates[_vm.langUrl].start[_vm.lang],"value":_vm.bloggerTrip.start,"error-messages":errors[0]},on:{"change":_vm.setStart}})]}}],null,false,1777179756)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextField',{staticClass:"label-on",attrs:{"label":_vm.translates[_vm.langUrl].duration[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.bloggerTrip.duration),callback:function ($$v) {_vm.$set(_vm.bloggerTrip, "duration", $$v)},expression:"bloggerTrip.duration"}})]}}],null,false,2326315713)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextField',{staticClass:"label-on",attrs:{"label":_vm.translates[_vm.langUrl].priceForPersone[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.bloggerTrip.price),callback:function ($$v) {_vm.$set(_vm.bloggerTrip, "price", $$v)},expression:"bloggerTrip.price"}})]}}],null,false,809968362)}),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextArea',{staticClass:"label-on",attrs:{"label":_vm.translates[_vm.langUrl].workshops[_vm.lang],"error-messages":errors[0]},model:{value:(_vm.bloggerTrip.workshops),callback:function ($$v) {_vm.$set(_vm.bloggerTrip, "workshops", $$v)},expression:"bloggerTrip.workshops"}})]}}],null,false,2884758900)}),_c('BaseButton',{staticClass:"trip-request__step-buttons__button button-fill",staticStyle:{"width":"100%"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.translates[_vm.langUrl].send[_vm.lang]))])],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }